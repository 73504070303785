<template>
    <Notas/>
</template>
<script>
import Notas from '@/components/Notas2.vue'
export default {
    components:{
        Notas
    }
}
</script>